export const FASTBOOKING_MODE_CHANGE_EVENT = 'fastbooking:mode-change'
export const FASTBOOKING_FLIGHT_TAB_CREATED_EVENT = 'fb_tab_created'
export const STANDARD_DATE_FORMAT = 'YYYY-MM-DD'

export const FASTBOOKING_EVENTS = {
  FASTBOOKING_READY: 'fb:ready',
  SET_DESTINATION: 'fb:set-destination',
  SET_PROMOCODE: 'fb:set-promocode',
  HIDE_FASTBOOKING: 'fb:hide-fastbooking',
  SHOW_FASTBOOKING: 'fb:show-fastbooking',
  OPEN_HIDDEN_FASTBOOKING: 'fb:open-Hidden-fastbooking',
  CLOSE_HIDDEN_FASTBOOKING: 'fb:close-Hidden-fastbooking',
  OPEN_DATES: 'fb:open-dates',
  OPEN_OCCUPANCY: 'fb:open-occupancy',
  OPEN_PROMOCODE: 'fb:open-promocode',
  SET_DATES: 'fb:set-dates',
  SET_OCCUPANCY: 'fb:set-occupancy',
  STATE_UPDATED: 'fb:state-updated',
  CLOSE_PANELS: 'fb:close-panels',
  FASTBOOKING_ACTIVE: 'fb:active',
  FASTBOOKING_INACTIVE: 'fb:inactive',
  RESET_PROMOCODE: 'fb:reset-promocode',
  PROMOCODE_IS_VALID: 'fb:promocode-is-valid',
  SET_PANELS_OFFSET_TOP: 'fb:set-panels-offset-top',
  REMOVE_PANELS_OFFSET_TOP: 'fb:remove-panels-offset-top',
}

export const IBH_FASTBOOKING_EVENTS = {
  STICKY_FASTBOOKING: 'ibh:fb:sticky',
  NO_STICKY_FASTBOOKING: 'ibh:fb:no-sticky',
  OPEN_STICKY_FASTBOOKING: 'ibh:fb:open',
  CLOSE_STICKY_FASTBOOKING: 'ibh:fb:close',
  SHOW_BANNER: 'ibh:fb:show-banner',
}

export const UtagEvents = {
  HotelTab: 'fb:utag:hotel-tab',
  FlightHotelTab: 'fb:utag:flight-hotel-tab',
  Dates: 'fb:utag:dates',
  Destination: 'fb:utag:destination',
  Promocode: 'fb:utag:promocode',
  PromocodeValidation: 'fb:utag:promocode-validation',
  PromocodeFocused: 'fb:utag:promocode-focused',
  Occupancy: 'fb:utag:occupancy',
  ConfirmOccupancy: 'fb:utag:confirm-occupancy',
  FlexibleDates: 'fb:utag:flexible-dates',
  ScrollToFastbookig: 'fb:utag:scroll-to-fastbooking',
  Submit: 'fb:utag:submit',
  AddMoreRooms: 'fb:utag:add-more-rooms',
}

export const DESTINATION_TYPES = {
  h: 'hotel',
  d: 'destination',
  s: 'specialty',
}
