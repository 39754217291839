/* eslint-disable */

import '../../vendors/template_engine/template_engine'
import Modal from '../../shared/modal/modal'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import _filter from 'lodash/filter'

import { readCookie } from '../../../../frontend/src/utils/cookie_manager'
import { currentDevice } from '../../core/utils/currentDevice'
import { getPrefix } from '../../core/utils/locale.js'
//import Fastbooking from '../fastbooking/fastbooking'
import { fillSearches, getLastSearches, PLACEHOLDER_IMAGE } from '../last_searches/last_searches'
import { STANDARD_DATE_FORMAT } from '../fastbooking/constants.js'

import { LAST_SEARCHES_LOADED_EVENT } from './last_searches_cards.constants.js'

dayjs.extend(customParseFormat)

if (currentDevice.isMobile) {
  import(`./last_searches_cards_mobile.scss`)
} else {
  import(`./last_searches_cards.scss`)
}

function init($container, load_last_search_to_fb) {
  const local_last_searches = getLastSearches({ stringify: true })
  let last_search_keys = []
  if (local_last_searches === '') return
  const last_searches = JSON.parse(local_last_searches)
  last_search_keys = lastSearchToUpdate(last_searches)

  const last_searches_shared = readCookie('shared_last_searches')

  if (last_searches_shared !== undefined) {
    //Stop to prevent double rendering
    return
  }
  if (last_search_keys.length > 0) {
    fetchLastSearch(last_search_keys, local_last_searches)
  } else if (local_last_searches != null && last_searches_shared === undefined) {
    initWithData(local_last_searches, $('.b-last-searches-cards'), load_last_search_to_fb)
    $('.b-last-searches-cards-home').addClass('show')
  }
}
function _init($container) {
  const local_last_searches = getLastSearches({ stringify: true })
  local_last_searches && initWithData(local_last_searches, $container)
}

export function fetchLastSearch(last_search_keys, local_last_searches) {
  if (local_last_searches !== null) $('.b-last-searches-cards-home').addClass('show')
  const locale_html = document.documentElement.getAttribute('data-js-lang')
  $.ajax({
    dataType: 'JSON',
    type: 'GET',
    url: `/${locale_html}/fetch_last_search/?ids=${last_search_keys.toString()}&current_locale=${document.documentElement.getAttribute(
      'data-js-lang'
    )}`,
    cache: true,
    success(searches_info) {
      if (last_search_keys.length > 0) {
        if (local_last_searches !== null) $('.b-last-searches-cards-home').addClass('show')
        fillSearches(searches_info, local_last_searches)
        local_last_searches = getLastSearches({ stringify: true })
      } else {
        //DefaultSearches are not shown anymore
        local_last_searches = fillDefaultSearches(searches_info);
      }
      initWithData(local_last_searches, $('.b-last-searches-cards'), true)
    },
  })
}

function lastSearchToUpdate(last_searches) {
  const last_searches_keys = []
  if (last_searches !== null && last_searches !== undefined) {
    $.each(last_searches, function (i, item) {
      try {
        if (
          item.title.length == 0 ||
          item.description.length == 0 ||
          item.image.length == 0 ||
          item.locale != document.documentElement.getAttribute('data-js-lang')
        ) {
          last_searches_keys.push(item.place_to_go)
        }
      } catch (err) {
        // Si tenemos alguna búsqueda antigua sin esos campos los pedimos
        last_searches_keys.push(item.place_to_go)
      }
    })
  }
  return last_searches_keys
}

function initWithData(last_searches, $container, load_last_search_to_fb) {
  let last_searches_formatted = JSON.parse(last_searches)
  last_searches_formatted = last_searches_formatted.length ? last_searches_formatted : getLastSearches()
  if (!last_searches_formatted.length) return
  // Loading last search into fastbooking if page is home
  // FAS PY06114 TODO: Remove if not needed after fastbooking refactor
  //if (load_last_search_to_fb) Fastbooking.loadLastSearch(last_searches_formatted[last_searches_formatted.length - 1])
  buildCards(last_searches_formatted, $container)
  if (IB.lazyImg !== undefined) IB.lazyImg.addImgToObserver($container)
}

function buildCards(searches, $container) {
  const containerRegular = $container.filter(e => !$($container[e]).hasClass('b-last-searches-cards-home'))
  const containerHome = $container.filter(e => $($container[e]).hasClass('b-last-searches-cards-home'))
  const $flexContainerRegular = $(containerRegular).find('.flex-container')
  const $flexContainerHome = $(containerHome).find('.flex-container')
  const date_format = $('html').data('date-format')
  const template = $('#last-search-card-template').html()
  const modal_data = {}
  let linkerParam = ''

  try {
    const tracker = ga.getAll()[0]
    linkerParam = tracker.get('linkerParam')
  } catch (err) {}
  searches = _filter(searches, function (o) {
    return o.search_url
  })


  if (searches.length > 3) {
    searches = searches.slice(Math.max(searches.length - 3, 1))

  }

  for (let i = searches.length - 1; i >= 0; i--) {
    const checkInDate = searches[i].check_in_date !==  null  ? searches[i].check_in_date : undefined
    const checkOutDate = searches[i].check_out_date !==  null ? searches[i].check_out_date : undefined
    let dates =
    checkInDate !== undefined
    ? checkOutDate !== undefined
    ? `${dayjs(checkInDate, STANDARD_DATE_FORMAT).format(date_format)} - ${dayjs(checkOutDate, STANDARD_DATE_FORMAT).format(date_format)}`
    : dayjs(checkInDate).format(date_format)
    : undefined
    const url_separator = searches[i].search_url.indexOf('?') >= 0 ? '&' : '?'

    dates = searches[i].next_opening == true ? $('#next-opening-copy').data('last-search') : dates

    let segmentations_html = ''
    const segmentations = searches[i].segmentations
    if (typeof segmentations !== 'undefined') {
      $.each(segmentations, function (i, value) {
        if (value == null) {
          return segmentations_html
        }
        segmentations_html += `<li class='badge-element'><span class='badge ${value.class} not-hover'>${value.title}</span></li>`
      })
    }

    const templateOptionsRegular = {
      title: searches[i].title,
      image: searches[i].image || PLACEHOLDER_IMAGE,
      image_class: searches[i].image_class,
      image_alt: searches[i].image_alt,
      image_placeholder_class: searches[i].image_placeholder_class,
      url: buildSharedUrl(searches[i].search_url, url_separator, linkerParam),
      index: i,
      dates,
      place_title: searches[i].place_title,
      segmentations: segmentations_html,
      locale: document.documentElement.getAttribute('data-js-lang'),
      utag_title: searches[i].title
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^\w\s]/gi, ''),
    }
    const categoryTag = searches[i].category ? `<span class="rating-stars r${searches[i].category}"></span>` : ''
    const templateOptionsHome = {
      ...templateOptionsRegular,
      title: searches[i].place_title ? searches[i].place_title + categoryTag : searches[i].title,
      image: null,
      image_class: null,
      image_alt: null,
      image_placeholder_class: null,
    }
    modal_data[i] = {
      title: searches[i].title,
      image: searches[i].image,
      image_class: searches[i].image_class,
      image_alt: searches[i].image_alt,
      image_placeholder_class: searches[i].image_placeholder_class,
      url: buildSharedUrl(searches[i].search_url, url_separator, linkerParam),
      place_title: searches[i].place_title,
      description: searches[i].description,
    }
    if (searches[i].search_url !== undefined && searches[i].search_url.length > 0) {
      /**
       * When mobile menu shows $flexContainerHome's content is already loaded. This condition prevents
       * content duplication.
       */
      if (
        !$flexContainerRegular[0] ||
        $flexContainerHome[0]?.childElementCount === $flexContainerRegular[0]?.childElementCount
      )
        $flexContainerHome.append(TemplateEngine(template, templateOptionsHome))
      $flexContainerRegular.append(TemplateEngine(template, templateOptionsRegular))
    }
  }

  if ($('.last-search-card').length > 0) {
    $container.removeClass('hidden')
  }

  activeSharedButton(modal_data)

  const searchesLoaded = new Event(LAST_SEARCHES_LOADED_EVENT)
  window.dispatchEvent(searchesLoaded)
}

function buildSharedUrl(search_url, url_separator, linkerParam) {
  return `${window.location.origin + getPrefix()}/shared/${search_url}${url_separator}${linkerParam}`
}

function getMobileOperatingSystem() {
  // Credit: Ryan kulp @ https://www.quora.com/Has-anyone-successfully-preset-body-copy-within-a-tap-to-SMS-link/answer/Ryan-Kulp
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
    return 'ios'
  }
  if (userAgent.match(/Android/i)) {
    return 'android'
  }
  return 'non-mobile or unknown'
}

/**
   *
   * @param element
   *  $text_to_copy: can be the inner text or the data-clipboard-text of selector
      $el_to_append: is the element where we append the hidden input from which we get value to copy.
      If element where we click to launch this functionality is a fancybox modal, we must append it to the modal itself.
      If not, we can append it to the body
   */
function copyToClipboard(element) {
  const $text_to_copy = $(element).data('clipboard-text')

  if (!$text_to_copy.length) return

  const $el_to_append = $(element).closest('.fancybox-container').length
    ? $(element).closest('.fancybox-container')
    : $(body)
  const $temp = $("<input readonly='true'>")

  $el_to_append.append($temp)
  $temp.val($text_to_copy).trigger('select')
  document.execCommand('copy')
  $temp.remove()
}

function activeSharedButton(modal_data) {
  const is_mobile = IB.currentDevice === 'mobile'
  const $doc = $(document)

  $doc
    .on('click', '.shared-block .last-search-card, .last-search-card .shared', function (e) {
      e.preventDefault()
      const $el = $(this).hasClass('shared') ? $(this).closest('.last-search-card') : $(this)
      const $a = $el.find('.shared')
      const $block = $($a[0].hash) // Window template
      const index = $el.data('index')

      $('#burgermenu-blocker:visible').trigger('click')

      Modal.show('shared-block', {
        onShow: () => {
          const template = $('#modal-desc-card-template').html()
          const data_index = $el.closest('.last-search-card').data('index')
          const data = modal_data[data_index]
          const $desc_cnt = $block.find('.desc-cnt')

          $('#shared-block').attr('index', index)
          $desc_cnt.find('.desc-card').remove()
          $block.find('.desc-cnt').append(TemplateEngine(template, data))

          $block.find('#shared-subject-title').val(data.title)

          $('.shared-block .desc-cnt .desc-card .desc-title .shared-searches-title').closest('.desc-title').hide()
          $('.shared-block .desc-cnt .desc-card .desc.desc-share').hide()
          $('.shared-block .desc-cnt .mobile-title.share-page').hide()

          if (!is_mobile) {
            $('.shared-block .social-share-list').show()
            $('.shared-block .form-cnt').hide()
          }

          IB.socialShareBuilder.shareTwitter($('.shared-block'), data)
          IB.socialShareBuilder.shareFacebook($('.shared-block'), data)
          IB.socialShareBuilder.shareByEmail($('.shared-block'), data)
        },
        onClose: () => {
        },
        awaitCloseAnimation: true,
      })
    })
    .on('click', '.b-last-searches-cards .last-search-card', function (e) {
      $(this).find('.l-external').trigger('click')
    })
    .on(
      'click',
      '.b-last-searches-cards .last-search-card .shared, .b-last-searches-cards .last-search-card .l-external',
      function (e) {
        e.stopPropagation()
      }
    )
    .on('click', '.shared-block .mobile-only .js-share-email', function (e) {
      showFormMobile()
    })
    .on('click', '.shared-block .come-back-btn', function () {
      if (IB.currentDevice !== 'mobile') return
      hideFormMobile()
    })
}

function showFormMobile() {
  $('.shared-block').find('.desc-cnt').addClass('hidden')
  $('.shared-block').find('.share-list').hide()
  $('.shared-block').find('.desc-cnt').hide()
  $('.shared-block').find('.share-page').addClass('hidden')
  $('.shared-block').find('.form-cnt').show()
}

function hideFormMobile() {
  $('.shared-block').find('.desc-cnt').removeClass('hidden')
  $('.shared-block').find('.share-list').show()
  $('.shared-block').find('.desc-cnt').show()
  $('.shared-block').find('.share-page').removeClass('hidden')
  $('.shared-block').find('.form-cnt').hide()
}

window.IB.lastSearchesCards = {
  init,
  getMobileOperatingSystem,
}

document.addEventListener('DOMContentLoaded', () => {
  const $b_last_searches_cards = $('.b-last-searches-cards')

  if (!$b_last_searches_cards.length) return false
  IB.lastSearchesCards.init($b_last_searches_cards, true)
})
