/**
 * Returns an object that only contains the specified properties from the original object.
 * @param {Object} object - The original object.
 * @param {string[]} properties - An array of strings with the names of the properties to keep.
 * @returns {Object} - A new object that only contains the specified properties from the original object.
 */
function filterObjectProperties(object, properties) {
  return Object.keys(object)
    .filter(property => properties.includes(property))
    .reduce((obj, property) => {
      obj[property] = object[property]
      return obj
    }, {})
}

export default filterObjectProperties
